import { object, string } from 'yup';

const FIELD_IS_REQUIRED = 'Field is required';
const INVALID_DOMAIN_NAME = 'Domain name can only contain letters, numbers, spaces and dashes';

export const schema = object().shape({
  firstName: string()
    .required(FIELD_IS_REQUIRED)
    .max(30),
  lastName: string()
    .required(FIELD_IS_REQUIRED)
    .max(30),
  email: string()
    .required(FIELD_IS_REQUIRED)
    .email(),
  company: string()
    .max(50),
  domainName: string()
    .required(FIELD_IS_REQUIRED)
    .max(30)
    .matches(/^[a-zA-Z0-9 -]+$/, INVALID_DOMAIN_NAME),
});
